import React from "react";
import { Global } from '@sourceflow-uk/sourceflow-sdk';
import SeoHead from '../components/SeoHead';
import FourOhFour from '../components/FourOFour';

export default function Fourohfour({ global }) {
	return (
		<>
			<SeoHead
				page_name={`404 - This page is not found | Staffing Agency | US and Canada Staffing | Bartech Staffing`}
			/>
			<FourOhFour
				global={global}
				pathPrefix={`404_page.fourofour`}
				customClass={``}
			/>
		</>
	)
}

export async function getStaticProps({ params }) {
	const global = (await Global()).toJson();

	return { props: { global } };
}